
import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationCode = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (pageNumber) => {
    console.log(pageNumber)
    onPageChange(pageNumber);
  };

  // const validTotalPages = Math.max(1, Math.ceil(totalPages || 1));

  return (
    <Pagination className='mt-3 justify-content-end'>
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {[...Array(totalPages)].map((_, index) => (
        <Pagination.Item
          key={index}
          active={index + 1 === currentPage}
          onClick={() => handlePageChange(index + 1)}
        >
          {index + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default PaginationCode;

