import React from "react";
import './homepage.css';
import _axiosInstance from "../../config/api";

function Homepage(){
    const [latestdocuments, setAllLatestDocuments] = React.useState(null);
    const [toatlFiles, setTotalFiles] = React.useState(null);
    React.useEffect(() => {
        const handleAllDocuments = async () => {
          const config = {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("360token")
              )}`,
              "content-type": "application/json",
            },
          };
          try {
            const responseData = await _axiosInstance.get(
              "/documents/list",
              config
            );
            setAllLatestDocuments(responseData?.data?.documents);
            
            setTotalFiles(responseData?.data?.Pagination.count ? responseData?.data?.Pagination.count : 0);

          } catch (error) {
            console.error(error);
          }
        };
        handleAllDocuments();
      }, []);

   
    return(
        <div>
            <div className="px_5 shadow d-flex rounded-3 box align-items-center gap_20 mt-5 wid_fitCont">
                
                <p className="m-0 font_62 fw-light text-primary-emphasis">{toatlFiles}</p>
                <div>
                    <p className="m-0 text-black-50 font_20 lh-sm fw-bolder">Files</p>
                    <p className="m-0 text-black-50 font_20 lh-sm fw-bolder">Uploaded</p>
                </div>
            </div>
            <div className="mt-5">
                <p className="fw-medium fs-5">Latest Files Uploaded</p>
                <div className="overflow_auto">
                    <table className="cust_table shadow">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Document Type</th>
                                <th>Uploaded By</th>
                            </tr>
                            {latestdocuments?.map((item, index) => (
                            <tr>
                                <td>{item.filename}</td>
                                <td>{item.description}</td>
                                <td>{item.documenttype}</td>
                                <td>{item.uploadedby}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
export default Homepage;
