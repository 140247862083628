import React from "react";
import "./login.css";
import { useState } from "react";
import headerlogo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import _axiosInstance from "../../config/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Login() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(null);
  const [otp, setOtp] = useState(null);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Define a regex pattern for allowed email domains
    const allowedDomains =
      /^(360degreecloud\.in|360degreecloud\.co\.in|360smsapp\.com|360degreecloud\.com|textolic\.com)$/;

    // Extract the domain from the email
    const domainMatch = email.match(/@([a-zA-Z0-9.-]+)$/);

    if (domainMatch && domainMatch[1]) {
      // Check if the extracted domain is allowed
      const domain = domainMatch[1];
      return allowedDomains.test(domain);
    } else {
      // No valid domain found
      return false;
    }
  };
  React.useEffect(()=>{
    const stayToken = localStorage.getItem("360token");
    if(stayToken){
      navigate("/dashboard/searchbar");
    }else{
      navigate("/");
    }
      
  },[])

  const handleVerifyOtp = async () => {
    try {
    
        const responseData = await _axiosInstance.post("/users/otpverify", {
          email: email,
          otp: otp,
        });
        localStorage.setItem(
          "360token",
          JSON.stringify(responseData?.data?.token)
        );
        if(responseData.data.status === 400){
          toast.error(responseData.data.message);
        }else{
          navigate("/dashboard/searchbar");
        }
        

    } catch (error) {
      toast.error(error);
    }
  };
  const handleLogin = async () => {
    try {
      if(validateEmail(email)){
        const responseData = await _axiosInstance.post("/users/sendotp", {
          email: email,
        });
        setShow(true);
      }else{
        toast.error("Invaild Email  Not Supported");
      }
    
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <div className="conatiner overflow_hidden">
        <div className="row main_conatiner ">
          <div className="col-md-6 col-3 d_none_mobile"></div>
          <div className="col-md-6 col_12">
            <p className="text-white fs-1 text-center cust_p100">
              Welcome to your <span className="fw-bold">AI Portal</span>
            </p>
            <p className="text-white text-center m-0 fs-5">Explore content effortlessly with our native search capabilities.</p>
            <div className="bg-white p-md-5 cust_card mt-4">
              <div className="text-center">
                <img alt="logo" src={headerlogo} className="logo_imgwd" />
              </div>

              {/* --------------------Send opt--------------- */}
              <div className={classNames(show ? "d_none" : "", "")}>
                <p className="card_text_head pt-4">Sign in to your account</p>
                <input
                  type="email"
                  class="form-control form-control-lg mt-4 fs-6"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div class="d-grid gap-2 col pt-4 mx-auto">
                  <button
                    type="button"
                    class="btn btn_light text-white btn-lg"
                    onClick={handleLogin}
                  >
                    Send OTP
                  </button>
                </div>
              </div>
              {/* --------------------Send opt--------------- */}
              <div className={show ? "" : "d_none"}>
                <p className="card_text_head pt-4">Sign in to your account</p>
                <input
                  type="text"
                  class="form-control form-control-lg mt-4 fs-6"
                  placeholder="Enter OTP"
                  onChange={(e) => setOtp(e.target.value)}
                />

                <div class="d-grid col pt-4 mx-auto">
                  
                  <button
                    type="button"
                    class="btn btn_light wid_100 text-white btn-lg"
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP
                  </button>
                  <div class="d-flex justify-content-between font_12">
                  <p className="text-center m-0">Didn't Receive OTP?</p>
                  <Link onClick={handleLogin} className="res_otp">Resend OTP</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
