import "./dashboard.css";
import Maintopbar from "../maintopbar/maintopbar";
import React, { useState } from "react";
import { Outlet, Link, Navigate } from "react-router-dom";
import {
  HomeOutlined,
  SearchOutlined,
  FolderOutlined,
  ArrowDropDown,
  Description,
} from "@mui/icons-material";

function PrivateDashboard() {
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("360token");
  const [activeButton, setActiveButton] = useState(0);
  const [activeFolderButton, setActiveFolderButton] = useState(0);
  const [openFolder, setOpenFolder] = useState(false);
  const [pramsData,setPramsData]=useState()
  const storedActiveButtonIndex = parseInt(
    localStorage.getItem("activeButtonIndex"),
    10
  );
  const storedActiveFolderButtonIndex = parseInt(
    localStorage.getItem("activeFolderButtonIndex"),
    10
  );
  React.useEffect(() => {
    setActiveButton(storedActiveButtonIndex || 0);
    setActiveFolderButton(storedActiveFolderButtonIndex || 0);
  }, [activeFolderButton, activeButton]);

  const onClickhandler = (index) => {
    localStorage.setItem("activeButtonIndex", index);
    setActiveButton(index);
    localStorage.removeItem("activeFolderButtonIndex");
    if (index === 3) {
      setOpenFolder(!openFolder);
    } else {
      setOpenFolder(false);
    }
  };

  const onClickFolderhandler = (val, index) => {
    console.log("onClickFolderhandler Index",index)
    localStorage.setItem("activeFolderButtonIndex", index);
    setActiveFolderButton(index);
    const data = { key: val, page: 1 };
    setPramsData(data)
  };
  const navigation = [
    { name: "Search", path: "searchbar", icon: <SearchOutlined />, list: [] },
    { name: "Dashboard", path: "homepage", icon: <HomeOutlined />, list: [] },
    {
      name: "Add Document",
      path: "addDocument",
      icon: <Description />,
      list: [],
    },
    {
      name: "Folder",
      path: null,
      icon: <FolderOutlined />,
      dropDown: <ArrowDropDown />,
      list: [],
    },
  ];
  const navigationsubforlder = [
    { name: "All Documents", path: "docFolder", value: "ALL" },
    { name: "Case Study", path: "docFolder", value: "Case study" },
    { name: "SOW/WBS", path: "docFolder", value: "SOW" },
    { name: "RFP", path: "docFolder", value: "RFP" },
    { name: "DFD/Flow Diagram", path: "docFolder", value: "DFD/ Flow Diagram" },
    { name: "Misc. Documents", path: "docFolder", value: "Misc. Documents" },
  ];
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div>
      {token ? (
        <div className="container_cust">
          {/*------------- for Web view sidebar -------------------------------*/}
          <div className={show ? "d_none_mob" : ""}>
            <div className="navigation for_desk">
              <ul className="ulclass one">
                {navigation.map((item, index) => (
                  <li className="">
                    <Link
                      onClick={(e) => {
                        if (item.name === "Folder") {
                          e.preventDefault();
                        }
                        onClickhandler(index);
                      }}
                      key={item.name}
                      to={item.name === "Folder" ? null : item.path}
                      className={classNames(
                        activeButton === index ? "active" : ""
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <span title={item.name}>{item.icon}</span>
                      <span className="title" title={item.name}>{item.name}</span>
                      <span className="drop_icon text-end">
                        {item.dropDown}
                      </span>
                    </Link>
                  </li>
                ))}

                {openFolder && (
                  <div className="w-full flex gap-1 pl-6 flex-col">
                    <ul>
                      {navigationsubforlder.map((item, index) => (
                        <li key={index}>
                          <Link
                            onClick={() =>
                              onClickFolderhandler(item.value, index)
                            }
                            key={item.name}
                            to={item.path}
                            className={classNames(
                              activeFolderButton === index ? "active" : ""
                            )}
                          >
                            <span className="title small_fs" title={item.name}>{item.name}</span>
                       </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </ul>
            </div>
          </div>
          {/*------------- for mobile view sidebar -------------------------------*/}
          <div className={show ? "dblock" : "for_mob"}>
            <div className="navigation ">
              <i
                className="fa-solid fa-xmark text-white float-end pe-3 fs-4"
                onClick={() => setShow(!show)}
              ></i>
              <ul className="ulclass one">
                {navigation.map((item, index) => (
                  <li className="">
                    <Link
                      onClick={(e) => {
                        if (item.name === "Folder") {
                          e.preventDefault();
                        }
                        if (item.name !== "Folder") {
                          setShow(!show);
                        }
                        onClickhandler(index);
                      }}
                      key={item.name}
                      to={item.name === "Folder" ? null : item.path}
                      state={{key:"Ravi Ojha"}}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <span>{item.icon}</span>
                      <span className="title">{item.name}</span>
                      <span>{item.dropDown}</span>
                    </Link>
                  </li>
                ))}

                {openFolder && (
                  <div className="w-full flex gap-1 pl-6 flex-col">
                    <ul>
                      {navigationsubforlder.map((item, index) => (
                        <li>
                          <Link
                            onClick={() =>
                              onClickFolderhandler(
                                item.value,
                                index,
                                setShow(!show)
                              )
                            }
                            key={item.name}
                            to={`${item.path}/${item?.value}`}
                            state={{key:item?.value,page:index}}
                            className={classNames(
                              activeFolderButton === index ? "active" : ""
                            )}
                          >
                            <span className="title small_fs">{item.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </ul>
            </div>
          </div>
          <div className={show ? "p-4" : " main p-4"}>
            <div className="for_mob_none">
              <i class="fa-solid fa-bars" onClick={() => setShow(!show)}></i>
            </div>

            <Maintopbar />
            <Outlet context={[pramsData]} />
          </div>
        </div>
      ) : (
        <Navigate to="/" replace />
      )}
    </div>
  );
}
export default PrivateDashboard;
