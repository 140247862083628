import React from "react";
import './Loader.css'
function Loader() {
  return (
    <div>
      <span class="loader"></span>
    </div>
  );
}

export default Loader;