import React, { useState } from "react";
import "./docFolder.css";
import _axiosInstance from "../../config/api";
import {
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import PaginationCode from "../PaginationCode/PaginationCode";
import Loader from "../loader/Loader";

function DocFolder() {
  const [pageCount, setPageCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [documents, setAllDocuments] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [pramsData] = useOutletContext();
  const dateFormat = (d) => {
    const dateString = d;
    const dateObject = new Date(dateString);

    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const fetchData = async (page) => {
    try {
      setLoader(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("360token")
          )}`,
          "content-type": "application/json",
        },
      };
      const response = await _axiosInstance.get(
        `documents/list?page=${
          page ? page : currentPage
        }&search=${search}&searchInDoc=${pramsData.key}`,
        config
      );
      setAllDocuments(response?.data?.documents);
      setLoader(false);
      console.log("response", response);
      if (response?.data?.title === "bad XRef entry") {
      } else {
        setPageCount(response.data.Pagination.pageCount);
      }
    } catch (error) {
      setLoader(false);
      console.log("No Data Found", error);
    }
  };

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    console.log("pageNumbercheck", pageNumber);
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  };

  const handleSearchDocuments = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("360token"))}`,
        "content-type": "application/json",
      },
    };
    try {
      const responseData = await _axiosInstance.get(
        `/documents/list?search=${search}`,
        config
      );
      setAllDocuments(responseData?.data?.documents);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    const handleAllDocuments = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("360token")
          )}`,
          "content-type": "application/json",
        },
      };
      try {
        setLoader(true);
        const responseData = await _axiosInstance.get(
          `/documents/list?searchInDoc=${pramsData.key}`,
          config
        );
        setAllDocuments(responseData?.data?.documents);
        setPageCount(responseData.data.Pagination.pageCount);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error(error);
      }
    };
    handleAllDocuments();
  }, [pramsData]);

  return (
    <div>
      <div className="row mt-5">
        <div className="col-md-7 col-12">
          <div class="input-group">
            <input
              type="text"
              className="form-control shadow-sm"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-btn">
              <button
                onClick={handleSearchDocuments}
                className="btn btn-default bg-secondary-subtle cut_btn_st"
                type="submit"
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-12 mt_sm-2 text-end">
          <button
            onClick={() => {
              navigate("/dashboard/addDocument");
            }}
            type="button"
            className="btn btn_light text-white px_5"
          >
            Add Document
          </button>
        </div>
      </div>
      <div className="mt-5">
        <p className="fw-medium fs-5">All Documents</p>
        <div className="overflow_auto">
          <table className="cust_table shadow">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Document Type</th>
                <th>Uploaded By</th>
                <th>Uploaded On</th>
                <th>File</th>
              </tr>
              {documents?.map((item, index) => (
                <tr>
                  <td>{item.filename}</td>
                  <td>{item.description}</td>
                  <td>{item.documenttype}</td>
                  <td>{item.uploadedby}</td>
                  <td>{dateFormat(item.createdAt)}</td>
                  <td>
                    {" "}
                    <i
                      onClick={async () => {
                        const config = {
                          headers: {
                            Authorization: `Bearer ${JSON.parse(
                              localStorage.getItem("360token")
                            )}`,
                            "content-type": "application/json",
                          },
                        };
                        try {
                          const responseData = await _axiosInstance.get(
                            `/documents/preview?fileUrl=${item.fileUrl}`,
                            config
                          );
                          console.log(responseData);
                          window.open(
                            responseData?.data,
                            "_blank",
                            "noreferrer"
                          );
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                      className="fa-solid fa-download cl_bl"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {loader && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader />
        </div>
      )}
      <PaginationCode
        currentPage={currentPage}
        totalPages={pageCount}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default DocFolder;
