import React, { useEffect, useRef, useState } from "react";
import "./searchbar.css";
import _axiosInstance from "../../config/api";
import Loader from "../loader/Loader";

function Searchbar() {
  const [search, setSearchAI] = React.useState(null);
  const [userstext, setUserInput] = React.useState([]);
  const resultContainerRef = useRef(null);
  const [loader, setLoader] = useState(false);

  const handleTextareaChange = (e) => {
    setSearchAI(e.target.value);
    e.target.style.height = "50px";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleSearchAI = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("360token"))}`,
        "content-type": "application/json",
      },
    };
    try {
      setLoader(true);
      setUserInput([
        ...userstext,
        {
          text: "",
          userInput: search,
        },
      ]);
      setSearchAI("");
      const responseData = await _axiosInstance.post(
        `/documents/aisearch`,
        { search: search },
        config
      );
      let updatedUserInputArray = [...userstext];
      updatedUserInputArray.push({
        text: responseData.data.data,
        userInput: search,
      });
      setUserInput(updatedUserInputArray);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setUserInput([
        ...userstext,
        {
          text: "Please Contact Administration",
          userInput: search,
        },
      ]);
      setLoader(false);
    }
  };
  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      console.log("test_", e.shiftKey);
      if (!e.shiftKey) {
        await handleSearchAI();
        e.target.style.height = "50px";
        setSearchAI("");
      }
    }
  };
  useEffect(() => {
    if (resultContainerRef.current) {
      resultContainerRef.current.scrollTop =
        resultContainerRef.current.scrollHeight;
    }
  }, [userstext]);
  return (
    <div>
      {loader && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader />
        </div>
      )}
      <div className="result_container mt-5" ref={resultContainerRef}>
        <div className="searchResult">
          {userstext.map((data) => {
            return (
              <>
                <div className="d-flex gap_10">
                  <div>
                    <p className="icon_box icon_box_bl">You</p>
                  </div>
                  <div>
                    {/* <p className="fw-bold m-0">You</p> */}
                    <p>{data.userInput}</p>
                  </div>
                </div>
                {data?.text ? (
                  <div className="d-flex gap_10">
                    <div>
                      <p className="icon_box icon_box_oran">Ask 360</p>
                    </div>
                    <div>
                      {data.text === "Please Contact Administration" ? (
                        <div>
                          <p style={{ color: "red" }}>{data.text}</p>
                        </div>
                      ) : (
                        <p>{data.text}</p>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </div>
      </div>

      <div className="search_conatiner">
        <textarea
          type="text"
          className="search_input shadow"
          placeholder="Search Here..."
          onChange={handleTextareaChange}
          value={search}
          onKeyPress={handleKeyPress}
        />
        <button className="btn_c" onClick={handleSearchAI}>
          <i class="fa-solid fa-paper-plane search_send_icon" title="Send Message"></i>
        </button>
      </div>
    </div>
  );
}

export default Searchbar;
