import React from "react";
import './maintopbar.css';
import headerlogo from '../../assets/logo.png';
import userlogo from '../../assets/user_logo.png';
import { useNavigate } from "react-router-dom";

function Maintopbar(){
    const navigate = useNavigate();
    return(
        <div className="d-flex justify-content-between align-items-center">
            <div>
                <img alt='aks 360 logo' className="img_logo" src={headerlogo}/>
            </div>
            <div className="align-items-center d-flex gap_10">
                {/* <i className="fa-solid fa-user fs-2"></i> */}
                <img alt='user' className="img_userlogo" src={userlogo}/>
                <div className="text-end">
                    {/* <p className="m-0 fw-bolder">Shushant k.</p> */}
                    <p className="m-0 fs_14 fw-bolder text-body-tertiary">360 Degree Cloud</p>
                    <button className="btn btn-base btn_custum" onClick={()=>{localStorage.removeItem("360token")
                    navigate('/')
                    localStorage.removeItem("activeButtonIndex")
                }} title="Logout">Logout<i class="fa-solid fa-right-from-bracket mx-2"></i></button>
                </div>
            </div>
        </div>
    );
}

export default Maintopbar;